import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  uncheckOthers(event) {
    if (event.currentTarget.checked) {
      event.currentTarget
        .closest('form')
        .querySelectorAll(`input[name="${event.currentTarget.name}"]`)
        .forEach(el => {
          if (el.value !== event.currentTarget.value) {
            el.checked = false
          }
        })
    }
  }
}
