import { Controller } from '@hotwired/stimulus'
import GLightbox from 'glightbox'

export default class extends Controller {
  connect() {
    this.lightbox = GLightbox(this.options())
    document.addEventListener('turbo:before-render', this.clean)
  }

  disconnect() {
    this.lightbox.settings.closeEffect = 'none'
    this.lightbox.destroy()
    this.lightbox = undefined
    document.removeEventListener('turbo:before-render', this.clean)
  }

  options() {
    const selector = this.element.dataset.selector || '.glightbox'
    return {
      selector: selector,
      moreLength: 0,
    }
  }

  clean() {
    document.body.classList.remove(
      'glightbox-open',
      'touching',
      'gdesc-open',
      'glightbox-touch',
      'glightbox-mobile',
      'gscrollbar-fixer'
    )
  }
}
