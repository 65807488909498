import ModalImageController from 'controllers/modal_image_controller'

export default class extends ModalImageController {
  options() {
    return {
      ...super.options(),
      skin: 'newarrival_importdress',
    }
  }
}
