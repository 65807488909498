import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  connect() {
    this.slider = new Swiper(this.element, {
      autoplay: { delay: 5000 },
      speed: 500,
      loop: true,
      spaceBetween: 40,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        slideChange: () => {
          this.element.querySelectorAll('.swiper-slide').forEach(slide => {
            slide.classList.add('u-special-life_photo-slide-transition')
          })
        },
      },
    })
  }

  disconnect() {
    this.slider.destroy()
    this.slider = null
  }
}
