import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'panel', 'overlay', 'button' ]

  connect() {
    this.STATE = 'is-show'
    this.NO_SCROLL = 'u-noscroll'
    this.html = document.querySelector('html')
    this.body = document.querySelector('body')
  }

  open(event) {
    event.preventDefault()
    this.html.classList.add(this.NO_SCROLL)
    this.body.classList.add(this.NO_SCROLL)
    this.panelTarget.classList.add(this.STATE)
    this.overlayTarget.classList.add(this.STATE)
  }

  close(event) {
    event.preventDefault()
    this.html.classList.remove(this.NO_SCROLL)
    this.body.classList.remove(this.NO_SCROLL)
    this.panelTarget.classList.remove(this.STATE)
    this.overlayTarget.classList.remove(this.STATE)
  }

  hideButton(event) {
    event.preventDefault()
    this.buttonTarget.classList.remove(this.STATE)
  }
}
