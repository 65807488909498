class MenuModal {
  constructor(targetDOM) {
    this.setParams(targetDOM)
    this.bindEvent()
  }
  setParams(targetDOM) {
    this.NO_SCROLL = 'u-noscroll'
    this.STATE = 'is-active'
    this.html = document.querySelector('html')
    this.body = document.querySelector('body')
    this.btn = targetDOM.getElementsByClassName('js-modal-trigger')[0]
    this.modal = targetDOM.getElementsByClassName('js-modal-wrapper')[0]
    this.closeButton = targetDOM.getElementsByClassName(
      'js-modal-close-button'
    )[0]
  }
  bindEvent() {
    this.btn.addEventListener('click', e => {
      e.preventDefault()
      this.show()
    })
    this.closeButton.addEventListener('click', e => {
      e.preventDefault()
      this.hide()
    })
  }
  show() {
    this.html.classList.add(this.NO_SCROLL)
    this.body.classList.add(this.NO_SCROLL)
    this.modal.classList.add(this.STATE)
  }
  hide() {
    this.html.classList.remove(this.NO_SCROLL)
    this.body.classList.remove(this.NO_SCROLL)
    this.modal.classList.remove(this.STATE)
  }
}
export default MenuModal
