import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  switch(event) {
    const id = event.currentTarget.dataset.tabId
    const index = event.currentTarget.dataset.tabIndex

    const tab = document.getElementById(id)
    const tabs = Array.prototype.slice.call(tab.firstElementChild.children)
    const panels = Array.prototype.slice.call(tab.lastElementChild.children)

    for (let element of tabs) {
      element.classList.remove('is-active')
    }
    for (let element of panels) {
      element.classList.remove('is-show')
    }
    tabs[index].classList.toggle('is-active')
    panels[index].classList.toggle('is-show')
  }
}
