import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['drawer']

  disconnect() {
    this.close()
  }

  open() {
    document.querySelector('html').classList.add('u-special-life_photo-noscroll')
    document.querySelector('body').classList.add('u-special-life_photo-noscroll')
    this.drawerTarget.classList.add('is-active')
  }

  close() {
    document.querySelector('html').classList.remove('u-special-life_photo-noscroll')
    document.querySelector('body').classList.remove('u-special-life_photo-noscroll')
    this.drawerTarget.classList.remove('is-active')
  }
}
