import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // const clickedNode = document.querySelector(`a[href="${location.pathname}"]`)
    // if (!clickedNode) {
    //   return
    // }
    // clickedNode.closest('ul').classList.value = 'l-sidenav__child-node--open'
    // clickedNode.closest('li').classList.add('current')
    const radios = document.querySelectorAll('[name="alert[target]"]')
    const checked = 'store'
    radios.forEach((e) => {
      if (e.checked) {
        this.controlTargetVisible(e.value)
      }
    })
  }

  change(e) {
    const target = e.currentTarget.value
    this.controlTargetVisible(target)
  }

  controlTargetVisible(target) {
    const storeNode = document.querySelector('.c-store-checkboxes')
    const brandNode =  document.querySelector('.c-brand-checkboxes')
    const topNode =  document.querySelector('.c-top-checkboxes')

    storeNode.classList.remove('p-alert-active')
    storeNode.classList.remove('p-alert-inactive')
    brandNode.classList.remove('p-alert-active')
    brandNode.classList.remove('p-alert-inactive')
    topNode.classList.remove('p-alert-active')
    topNode.classList.remove('p-alert-inactive')

    if (target === 'brand') {
      storeNode.classList.add('p-alert-inactive')
      brandNode.classList.add('p-alert-active')
      topNode.classList.add('p-alert-inactive')
    }  else if (target === 'top') {
      storeNode.classList.add('p-alert-inactive')
      brandNode.classList.add('p-alert-inactive')
      topNode.classList.add('p-alert-active')
    } else {
      storeNode.classList.add('p-alert-active')
      brandNode.classList.add('p-alert-inactive')
      topNode.classList.add('p-alert-inactive')
    }
  }
}
