import { Controller } from "@hotwired/stimulus"

// submit時にパラメータのソートと空パラメータの間引きを行う、以下を追加すると動く
// - formにdata-controller="search"を追加
// - formにdata-target="search.form"を追加
export default class extends Controller {
  static targets = [ 'form' ]

  connect() {
    this.form.addEventListener('submit', (event) => {
      event.preventDefault();
      const params = new URLSearchParams();
      Array.from(this.form.querySelectorAll('input[name],select[name]')).forEach((e) => {
        if (!e.disabled && e.closest('[disabled]') === null && !params.getAll(e.name).includes(e.value)) {
          if ((e.type === 'checkbox' || e.type === 'radio')) {
            if (e.checked) {
              params.append(e.name, e.value)
            }
          } else if(e.type !== 'submit' && e.value) {
            params.append(e.name, e.value)
          }
        }
      })
      if (params.has('brand_id') && params.has('store_ids[]')) {
        params.delete('brand_id')
      }
      params.sort()
      const queryStr = params.toString()
      location.href = `${this.form.action}${queryStr ? '?' + params.toString() : ''}`
    })
  }

  clear(event) {
    event.preventDefault()

    Array.from(this.form.elements).forEach((input) => {
      switch(input.type) {
        case "text":
        case "password":
        case "textarea":
          input.value = "";
          return;
        case "checkbox":
        case "radio":
          input.checked = false;
          return;
        case "select-one":
        case "select-multiple":
          input.selectedIndex = 0;
          return;
        default:
      }
    })
  }

  get form() {
    return this.formTarget
  }
}
