import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'visitStoreFormGroup', 'preferredDateLabel' ]

  changeToVisitStore() {
    this.visitStoreFormGroupTarget.classList.remove('u-hidden')
    this.preferredDateLabelTargets.forEach((label) => {
      label.textContent = label.textContent.replace('オンライン相談', 'ご来店')
    })
  }

  changeToOnline() {
    this.visitStoreFormGroupTarget.classList.add('u-hidden')
    this.preferredDateLabelTargets.forEach((label) => {
      label.textContent = label.textContent.replace('ご来店', 'オンライン相談')
    })
  }
}
