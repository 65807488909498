import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'tagFormGroup', 'photographerTags', 'hairMakeupTags', 'experienceFormGroup' ]

  toggle(event) {
    const value = event.currentTarget.value
    if (value.includes('photographer')) {
      this.tagFormGroupTarget.classList.remove('hidden')
      this.enableTags(this.photographerTagsTarget)
      this.disableTags(this.hairMakeupTagsTarget)
      this.enableExperience(this.experienceFormGroupTarget)
    } else if (value.includes('hair_makeup')) {
      this.tagFormGroupTarget.classList.remove('hidden')
      this.disableTags(this.photographerTagsTarget)
      this.enableTags(this.hairMakeupTagsTarget)
      this.enableExperience(this.experienceFormGroupTarget)
    } else {
      this.tagFormGroupTarget.classList.add('hidden')
      this.disableTags(this.photographerTagsTarget)
      this.disableTags(this.hairMakeupTagsTarget)
      this.disableExperience(this.experienceFormGroupTarget)
    }
  }

  enableTags(target) {
    target.classList.remove('hidden')
    target.querySelectorAll('input[type="checkbox"]').forEach((el) => {
      el.disabled = false
    })
  }

  disableTags(target) {
    target.classList.add('hidden')
    target.querySelectorAll('input[type="checkbox"]').forEach((el) => {
      el.disabled = true
    })
  }

  enableExperience(target) {
    target.classList.remove('hidden')
    target.querySelector('select').disabled = false
    target.querySelector('input[type="hidden"]').disabled = true
  }

  disableExperience(target) {
    target.classList.add('hidden')
    target.querySelector('select').disabled = true
    target.querySelector('input[type="hidden"]').disabled = false
  }
}
