import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'counter']

  initialize() {
    this.update = this.update.bind(this)
  }

  connect() {
    this.update()
    this.inputTarget.addEventListener('input', this.update)
  }

  update() {
    this.counterTarget.textContent = this.count
  }

  get count() {
    return this.inputTarget.value.length
  }
}
