import DragSortController from "./drag_sort_controller"
import Rails from "@rails/ujs"

export default class extends DragSortController {
  end(event) {
    const id = event.item.dataset.id
    const parentId = event.item.dataset.parent_id
    const data = new FormData()
    data.append("position", event.newIndex + 1)

    Rails.ajax({
      url: this.urlValue.replace(":id", id).replace(":parent_id", parentId),
      type: 'PATCH',
      data: data
    })
  }
}
