import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  change({ params: { visitUrl, onlineUrl, tel } }) {
    if (visitUrl) {
      document.querySelectorAll('.js-navigation-link-visit').forEach(link => {
        link.href = visitUrl
        if (visitUrl.startsWith('http')) {
          link.target = '_blank'
          link.rel = 'noopener'
        } else {
          link.target = '_self'
          link.rel = ''
        }
      })
    }

    if (onlineUrl) {
      document.querySelectorAll('.js-navigation-link-online').forEach(link => {
        link.href = onlineUrl
        if (onlineUrl.startsWith('http')) {
          link.target = '_blank'
          link.rel = 'noopener'
        } else {
          link.target = '_self'
          link.rel = ''
        }
      })
    }

    if (tel) {
      document.querySelectorAll('.js-navigation-link-tel').forEach(link => {
        link.href = tel
      })
    }
  }
}
