import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const clickedNode = document.querySelector(`a[href="${location.pathname}"]`)
    if (!clickedNode) { return }
    clickedNode.closest('ul').classList.value = 'l-sidenav__child-node--open'
    clickedNode.closest('li').classList.add('current')
  }

  toggle(event) {
    let target = event.currentTarget.querySelector('ul')
    if (!target) {
      return
    }
    let prev = target.classList.value
    let current = (prev === 'l-sidenav__child-node--open') ? 'l-sidenav__child-node--close' : 'l-sidenav__child-node--open'
    target.classList.remove(prev)
    target.classList.add(current)
  }
}
