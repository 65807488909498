import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'category', 'kimono', 'kimonoArea', 'dress', 'dressArea' ]

  connect() {
    const category = this.categoryTarget.value
    this.initCategory(category)
  }

  fire(event) {
    this.initCategory(event.currentTarget.value)
  }

  initCategory(category) {
    if (category && category == 0) {
      this.kimonoAreaTarget.style.display = 'inline-block'
      this.dressAreaTarget.style.display = 'none'
      this.dressTarget.value = ''
    } else if (category && category == 1) {
      this.kimonoAreaTarget.style.display = 'none'
      this.kimonoTarget.value = ''
      this.dressAreaTarget.style.display = 'inline-block'
    } else {
      this.kimonoAreaTarget.style.display = 'none'
      this.dressAreaTarget.style.display = 'none'
      this.kimonoTarget.value = ''
      this.dressTarget.value = ''
    }
  }
}
