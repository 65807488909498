class SmoothScroll {
  constructor(targetDOM) {
    this.setParams(targetDOM)
    this.bindEvent()
  }
  setParams(targetDOM) {
    this.btn = targetDOM
  }
  bindEvent() {
    this.btn.addEventListener('click', e => {
      e.preventDefault()
      const target = e.currentTarget
      const targetId = target.hash
      const targetElement = document.querySelector(targetId)
      const rectTop = targetElement.getBoundingClientRect().top
      const offsetTop = window.pageYOffset
      const buffer = 10
      const top = rectTop + offsetTop - buffer
      window.scrollTo({
        top,
        behavior: 'smooth',
      })
    })
  }
}
export default SmoothScroll
