import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['changeUpImage', 'changeBodyImage']

  initialize() {
    this.imageStyledUp = 0
    this.imageStyledBody = 0
    this.exClass = 'is-styled0'
  }

  checkValues(elements) {
    const len = elements.length
    let checkValue = ''
    for (let i = 0; i < len; i++) {
      if (elements.item(i).checked){
        checkValue = elements.item(i).value
      }
    }
    return checkValue
  }

  changeStyledUp(event) {
    event.preventDefault()
    const elements = document.getElementsByName('con_type_1')
    this.imageStyledUp = this.checkValues(elements)
    this.changeImageClass(this.imageStyledUp, this.exClass, this.changeUpImageTarget)
  }

  changeStyledBody(event) {
    event.preventDefault()
    const elements = document.getElementsByName('con_type_2')
    this.imageStyledBody = this.checkValues(elements)
    this.changeImageClass(this.imageStyledBody, this.exClass, this.changeBodyImageTarget)
  }

  changeImageClass(type, exClass, target) {
    target.classList.remove(exClass)
    target.classList.add('is-styled'+ type)
    this.exClass = 'is-styled'+ type
  }
}
