import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "type" ]

    changeType() {
        location.pathname = (this.type === 0) ? '/admin/galleries/costumes/new' : '/admin/galleries/hair_makes/new'
    }
    get type() {
        return this.typeTarget.options.selectedIndex
    }
}
