import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'menu', 'brands' ]

  connect() {
    this.listener = this.toggle.bind(this)
    window.addEventListener('scroll', this.listener)
  }

  disconnect() {
    window.removeEventListener('scroll', this.listener)
  }

  toggle() {
    const offset = (window.innerWidth * 120 / 375) + 20
    if (this.brandsTarget.getBoundingClientRect().top > offset) {
      this.menuTarget.classList.add('is-hidden')
    } else {
      this.menuTarget.classList.remove('is-hidden')
    }
  }
}
