import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'form', 'input' ]

  preventUncheck(event) {
    if (!event.currentTarget.checked) {
      event.preventDefault()
    }
  }

  uncheckOtherInputs(event) {
    this.inputTargets.forEach((input) => {
      if (input.name === event.currentTarget.name && input !== event.currentTarget) {
        input.checked = false
      }
    })
  }

  submit(event) {
    event.preventDefault()

    if (this.formTarget.querySelector('input[name="store_ids\\[\\]"]:checked')) {
      const brandIdInput = this.formTarget.querySelector('input[name="brand_id"]')
      if (brandIdInput) {
        brandIdInput.disabled = true
      }
    }

    if (event.params.frameId) {
      this.formTarget.dataset.turboFrame = event.params.frameId
    }

    this.formTarget.requestSubmit()

    if (event.params.frameId && this.formTarget.dataset.turboFrame) {
      delete this.formTarget.dataset.turboFrame
    }
  }
}
