import Flatpickr from 'stimulus-flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja.js'
import 'flatpickr/dist/themes/light.css'

export default class extends Flatpickr {
  static values = { holidays: Array }

  initialize() {
    const holidays = this.holidaysValue
    this.config = {
      locale: Japanese,
      disableMobile: true,
      onDayCreate: function(_dObj, _dStr, _fp, dayElem) {
        const dayOfWeek = dayElem.dateObj.getDay()
        const date = dayElem.dateObj.toLocaleDateString('ja-JP', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).split('/').join('-')

        if (dayOfWeek === 0 || holidays.includes(date)) {
          dayElem.classList.add('is-holiday')
        } else if (dayOfWeek === 6) {
          dayElem.classList.add('is-saturday')
        }
      },
    }
  }

  connect() {
    super.connect()
    const clearButtonController = this.element.closest(
      '*[data-controller="input-clear-button"]'
    )
    if (clearButtonController) {
      this.clearButton = clearButtonController.querySelector(
        '*[data-action="input-clear-button#clear"]'
      )
      if (this.clearButton) {
        this.clearCallback = () => this.fp.clear()
        this.clearButton.addEventListener('click', this.clearCallback)
      }
    }
  }

  disconnect() {
    if (this.clearButton) {
      if (this.clearCallback) {
        this.clearButton.removeEventListener(this.clearCallback)
        this.clearCallback = null
      }
      this.clearButton = null
    }
    super.disconnect()
  }
}
