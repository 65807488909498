import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'selectedContainer' ]

  template(self_model, attach_model, name, id) {
    return `<span class="${attach_model}-name"> ${this.escapeHtml(name)}</span>
<i class="fa fa-times" data-action="click->admin--${self_model.replace('_', '-')}#deleteSet"></i>
<input type="hidden" value="${id}" name="${self_model}[${attach_model}_ids][]" data-name="${this.escapeHtml(name)}" class="selected_${attach_model}s" />`
  }

  escapeHtml(str) {
    return str
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  get container() {
    return this.selectedContainerTarget
  }
}
