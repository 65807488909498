import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  static targets = [ 'slider', 'thumbnail' ]
  static values = { index: Number }
  static classes = [ 'selected' ]

  connect() {
    this.slider = new Swiper(this.sliderTarget, {
      speed: 500,
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      on: {
        slideChange: () => {
          this.indexValue = this.slider.realIndex
        }
      }
    })
    this.indexValue = 0
  }

  disconnect() {
    this.slider.destroy()
    this.slider = undefined
  }

  select(event) {
    this.slider.slideToLoop(event.params.index)
    this.indexValue = event.params.index
  }

  indexValueChanged(value, prev = 0) {
    this.thumbnailTargets[prev].classList.remove(this.selectedClass)
    this.thumbnailTargets[value].classList.add(this.selectedClass)
  }
}
