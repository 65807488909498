import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'selectedContainer' ]

  changeSet(e) {
    const target = e.currentTarget
    if (!target) return
    const option = document.querySelector(`#set-plans option[value="${target.value.replace(/["\\]/g, '\\$&')}"]`)
    if (!option) return
    const li = document.createElement('li')
    li.classList.add('c-selected-items__item')
    li.innerHTML = this.template(target.value, option.dataset.set_plan_id)
    this.container.append(li)
    option.remove()
    target.value = ''
  }

  deleteSet(e) {
    const item = e.currentTarget.closest('.c-selected-items__item')
    if (!item) return
    const selected = item.querySelector('.selected_set_plans')
    if (!selected) return
    const option = document.createElement('option')
    option.setAttribute('data-set_plan_id', selected.value)
    option.setAttribute('value', selected.dataset.name)
    document.querySelector('#set-plans').append(option)
    item.remove()
  }

  template(name, setPlanId) {
    return `<span class="set-plan-name"> ${this.escapeHtml(name)}</span>
<i class="fa fa-times" data-action="click->admin--plan#deleteSet"></i>
<input type="hidden" value="${setPlanId}" name="plan[set_plan_ids][]" data-name="${this.escapeHtml(name)}" class="selected_set_plans" />`
  }

  escapeHtml(str) {
    return str
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
  }

  get container() {
    return this.selectedContainerTarget
  }
}
