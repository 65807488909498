import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'category', 'kimono', 'dress' ]

  connect() {
    const selectedCategory = this.categoryTarget.value
    this.toggle(selectedCategory)
  }

  change(event) {
    this.toggle(event.currentTarget.value)
  }

  toggle(category) {
    if (!category) return
    if (category === 'dress') {
      this.kimonoTarget.style.display = 'none'
      this.dressTarget.style.display = ''
    } else {
      this.kimonoTarget.style.display = ''
      this.dressTarget.style.display = 'none'
    }
  }
}
