import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  static targets = [ 'mainSlider', 'thumbnailSlider' ]

  connect() {
    const thumb = new Swiper(this.thumbnailSliderTarget, {
      slidesPerView: 'auto',
      allowTouchMove: false,
      shortSwipes: false,
      slideToClickedSlide: true,
      preventClicksPropagation: false,
      preventClicks: false
    })
    const main = new Swiper(this.mainSliderTarget, {
      loop: true,
      slidesPerView: 'auto',
      thumbs: {
        swiper: thumb
      },
    })
  }
}
