// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import '../css/application-pc.scss'
Rails.start()
ActiveStorage.start()

import Swiper from 'swiper'
import TabSwitcher from './modules/pc/tab'
import TabButtonSwitcher from './modules/pc/tab-button'
import Accordion from './modules/pc/accordion'
import BlockAccordion from './modules/pc/block-accordion'
import MenuModal from './modules/pc/menu-modal'
import SmoothScroll from './modules/pc/smooth-scroll'
import SmoothScrollPolyfill from 'smoothscroll-polyfill';
import 'lazysizes';
import 'controllers'
import '../components-pc'

window.addEventListener('turbo:before-render', function() {
  location.reload();
})

window.addEventListener('turbo:load', function() {
  const IMAGE_SLIDER_COUNT = document.querySelectorAll('.js-image-slider-loop').length;
  new Swiper('.js-image-slider', {
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: IMAGE_SLIDER_COUNT > 1,
    watchOverflow: true
  })

  const sliderThumbnail = new Swiper('.js-pc-campaign-thumbnail', {
    slidesPerView: 4,
    spaceBetween: 16,
  })

  new Swiper('.js-pc-campaign-slider', {
    thumbs: {
      swiper: sliderThumbnail,
    },
  })

  const horizonalSliders = document.querySelectorAll('.js-horizonal-slider')
  horizonalSliders.forEach(slide => {
    const thumbnail = slide.nextSibling
    new Swiper(slide, {
      thumbs: {
        swiper:
          thumbnail && thumbnail.classList.contains('js-horizonal-thumbnail')
            ? new Swiper(thumbnail, {
                spaceBetween: 10,
                slidesPerView: 4,
              })
            : undefined,
      },
    })
  })

  new Swiper('.js-image-slider-zoom', {
    slidesPerView: 5,
    spaceBetween: 10,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })

  const TAB_JS_ROOT_DOMS = document.querySelectorAll('.js-tab')
  if (TAB_JS_ROOT_DOMS.length > 0) {
    TAB_JS_ROOT_DOMS.forEach(dom => {
      new TabSwitcher(dom)
    })
  }

  const TAB_BUTTON_JS_ROOT_DOMS = document.querySelectorAll('.js-tab-button')
  if (TAB_BUTTON_JS_ROOT_DOMS.length > 0) {
    TAB_BUTTON_JS_ROOT_DOMS.forEach(dom => {
      new TabButtonSwitcher(dom)
    })
  }

  const ACCORDION_JS_ROOT_DOM = document.querySelectorAll('.js-accordion')
  if (ACCORDION_JS_ROOT_DOM.length > 0) {
    ACCORDION_JS_ROOT_DOM.forEach(dom => {
      dom.querySelectorAll('.js-accordion-item').forEach(item => {
        new Accordion(item)
      })
    })
  }

  const BLOCK_ACCORDION_JS_ROOT_DOMS = document.querySelectorAll(
    '.js-block-accordion'
  )
  if (BLOCK_ACCORDION_JS_ROOT_DOMS.length > 0) {
    BLOCK_ACCORDION_JS_ROOT_DOMS.forEach(dom => {
      new BlockAccordion(dom)
    })
  }

  const MENU_MODAL_JS_ROOT_DOMS = document.querySelectorAll('.js-modal')
  if (MENU_MODAL_JS_ROOT_DOMS.length > 0) {
    MENU_MODAL_JS_ROOT_DOMS.forEach(dom => {
      new MenuModal(dom)
    })
  }

  const SMOOTH_SCROLL_JS_ROOT_DOM = document.querySelectorAll('.js-smooth-scroll')
  if (SMOOTH_SCROLL_JS_ROOT_DOM.length > 0) {
    SmoothScrollPolyfill.polyfill()
    SMOOTH_SCROLL_JS_ROOT_DOM.forEach(dom => {
      new SmoothScroll(dom)
    })
  }
})
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
