import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  selectTag(event) {
    const sel = event.currentTarget
    const tag = sel.options[sel.selectedIndex].text.replace('#', '')
    const prevTag = sel.dataset.trackEvent.split(' ')[2]
    sel.dataset.trackEvent = sel.dataset.trackEvent.replace(prevTag, tag)

    location.href = sel.value
  }
}
