class TabButtonSwitcher {
  constructor(targetDOM) {
    this.setParams(targetDOM)
    this.bindEvent()
  }
  setParams(targetDOM) {
    this.target = targetDOM
    this.tabs = Array.prototype.slice.call(targetDOM.querySelector('.js-tab-button-tabs').children)
    this.panels = Array.prototype.slice.call(targetDOM.querySelector('.js-tab-button-panels').children)
  }
  bindEvent() {
    this.tabs.forEach((element, index) => {
      element.addEventListener('click', e => {
        this.tabSwitch(index)
      })
    })
  }
  tabSwitch(index) {
    for (let element of this.tabs) {
      element.classList.remove('is-active')
    }
    for (let element of this.panels) {
      element.classList.remove('is-show')
    }
    this.tabs[index].classList.toggle('is-active')
    this.panels[index].classList.toggle('is-show')
  }
}
export default TabButtonSwitcher
