import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['category']

  switch({ params: { name } }) {
    this.categoryTargets.forEach(el => {
      if (el.dataset.name === name) {
        el.classList.add(el.dataset.klass)
      } else {
        el.classList.remove(el.dataset.klass)
      }
    })
  }
}
