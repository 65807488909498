import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.trix-content a').forEach(link => {
      link.addEventListener('click', function(e) {
        const el = e.target
        if (el.tagName === 'A' && !el.isContentEditable && el.host != window.location.host) {
          el.setAttribute("target", "_blank")
          el.setAttribute('rel', 'noopener')
        }
      }, true)
    })
  }
}
