import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  change(event) {
    const target = event.currentTarget
    const index = target.selectedIndex
    if (index === undefined) return
    const link = target.parentNode.querySelector('.c-pager-number__link')
    link.href = target.options[index].value
    link.click()
  }
}
