class Accordion {
  constructor(targetDOM) {
    this.setParams(targetDOM)
    this.bindEvent()
  }
  setParams(targetDOM) {
    this.buttons = Array.prototype.slice.call(
      targetDOM.getElementsByClassName('js-accordion-item-trigger')
    )
  }
  bindEvent() {
    this.buttons.forEach(function(button) {
      button.addEventListener('click', e => {
        e.preventDefault()
        e.currentTarget.classList.toggle('is-active')
        e.currentTarget.nextElementSibling.classList.toggle('is-active')
      })
    })
  }
}
export default Accordion
