import BaseAttachedItemController from "./base_attached_item_controller"

export default class extends BaseAttachedItemController {
  changeSet(e) {
    const target = e.currentTarget
    if (!target) return
    const option = document.querySelector(`#costumes option[value="${target.value.replace(/["\\]/g, '\\$&')}"]`)
    if (!option) return
    const li = document.createElement('li')
    li.classList.add('c-selected-items__item')
    li.innerHTML = this.template('costume_blog', 'costume', target.value, option.dataset.costume_id)
    this.container.append(li)
    option.remove()
    target.value = ''
  }

  deleteSet(e) {
    const item = e.currentTarget.closest('.c-selected-items__item')
    if (!item) return
    const selected = item.querySelector('.selected_costumes')
    if (!selected) return
    const option = document.createElement('option')
    option.setAttribute('data-costume_id', selected.value)
    option.setAttribute('value', selected.dataset.name)
    document.querySelector('#costumes').append(option)
    item.remove()
  }
}
