import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      centeredSlides: true,
      slidesPerView: 1.35,
      loop: true,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
  }
}
