import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'link' ]

  connect() {
    this.delete = false
  }

  click(event) {
    if (confirm('削除してもよろしいですか？')) {
      this.delete = true
      this.linkTarget.addEventListener('ajax:success', this.onSuccess.bind(this))
      this.linkTarget.addEventListener('ajax:error', this.onError.bind(this))
    } else {
      event.preventDefault()
    }
  }

  onSuccess(event) {
    this.element.parentNode.removeChild(this.element)
    alert('削除しました。')
  }

  onError(event) {
    if (this.delete) {
      this.linkTarget.removeEventListener('ajax:success', this.onSuccess.bind(this))
      this.linkTarget.removeEventListener('ajax:error', this.onError.bind(this))
      this.delete = false;
      const [data, status, xhr] = event.detail
      alert(data.msg || 'エラーが発生しました。時間を置いてもう一度実行してください。')
    }
  }
}
