import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  static targets = [ 'mainSlider', 'prevButton', 'nextButton']

  connect() {
    const main = new Swiper(this.mainSliderTarget, {
      loop: true,
      slidesPerView: 'auto',
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        prevEl: this.prevButtonTarget,
        nextEl: this.nextButtonTarget,
      }
    })
  }
}
