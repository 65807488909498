import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    offset: { type: Number, default: 200 },
    delay: { type: Number, default: 150 }
  }

  connect() {
    this.prevPos = document.documentElement.scrollTop || document.body.scrollTop
    this.listener = this.handleScroll.bind(this)
    this.timer = null
    window.addEventListener('scroll', this.listener)
    if (window.scrollY > this.offsetValue) {
      this.element.classList.add('is-show')
    }
  }

  disconnect() {
    window.removeEventListener('scroll', this.listener)
    this.prevPos = null
    this.listener = null
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = null
  }

  handleScroll() {
    const currentPos =
      document.documentElement.scrollTop || document.body.scrollTop

    if (window.scrollY > this.offsetValue) {
      if (currentPos > this.prevPos) {
        // 下にスクロール時は非表示
        this.element.classList.remove('is-show')

        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          if (window.scrollY > this.offsetValue) {
            // スクロール停止後、ページ先頭でなければ表示
            this.element.classList.add('is-show')
          }
        }, this.delayValue)
      }
    } else {
      // ページ先頭では非表示
      this.element.classList.remove('is-show')
    }

    this.prevPos = currentPos
  }
}
