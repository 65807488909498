import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hidden']

  onChange(event) {
    const checked = event.currentTarget.checked
    this.hiddenTargets.forEach(el => {
      el.disabled = !checked
    })
  }
}
