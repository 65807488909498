import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link', 'select']

  sort() {
    this.linkTarget.href = this.selectTarget.value
    this.linkTarget.click()
  }
}
