import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'button']

  connect() {
    this.toggleButton()
  }

  clear() {
    this.inputTarget.value = ''
    this.toggleButton()
  }

  toggleButton() {
    if (this.inputTarget.value) {
      this.buttonTarget.classList.add('is-active')
    } else {
      this.buttonTarget.classList.remove('is-active')
    }
  }
}
