import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  static targets = [ 'slider', 'thumbs', 'prevButton', 'nextButton', 'pagination' ]
  static values = { options : { type: Object, default: {} }, thumbsOptions: { type: Object, default: {} } }

  connect() {
    if (this.hasThumbsTarget) {
      this.thumbs = new Swiper(this.thumbsTarget, this.thumbsOptionsValue)
    }
    this.slider = new Swiper(this.sliderTarget, this.options())
  }

  disconnect() {
    this.slider.destroy()
    this.slider = null
    if (this.hasThumbsTarget) {
      this.thumbs.destroy()
      this.thumbs = null
    }
  }

  options() {
    let options = this.optionsValue
    if (this.hasPrevButtonTarget && this.hasNextButtonTarget) {
      options = {
        ...options,
        navigation: {
          prevEl: this.prevButtonTarget,
          nextEl: this.nextButtonTarget
        }
      }
    }
    if (this.hasPaginationTarget) {
      options = {
        ...options,
        pagination: {
          el: this.paginationTarget,
          clickable: true
        }
      }
    }
    if (this.hasThumbsTarget) {
      options = {
        ...options,
        thumbs: {
          swiper: this.thumbs
        }
      }
    }
    return options
  }
}
