import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['categoryType', 'costumeType', 'silhouette', 'size', 'color', 'design', 'type' ]

  connect() {
    this.setFieldset()
  }

  changeCategory(event) {
    const category = event.currentTarget.value
    this.setCategoryType(category)
    this.setCostumeType(category)
    this.setFieldset()
  }

  setCategoryType(category) {
    this.categoryTypeTarget.disabled = category === ''
    if (category === 'dress' || category === 'tuxedo') {
      this.categoryTypeTarget.value = 'dress'
    } else if (category === 'kimono' || category === 'man') {
      this.categoryTypeTarget.value = 'kimono'
    }
  }

  setCostumeType(category) {
    this.costumeTypeTarget.disabled = !(category === 'tuxedo' || category === 'man')
    if (category === 'tuxedo') {
      this.costumeTypeTarget.value = 'tuxedo'
    } else if (category === 'man') {
      this.costumeTypeTarget.value = 'man'
    } else {
      this.costumeTypeTarget.value = ''
    }
  }

  setFieldset() {
    const categoryType = this.categoryTypeTarget.value
    const costumeType = this.costumeTypeTarget.value

    if (costumeType === 'white') {
      this.disableFieldset(this.silhouetteTarget)
      this.disableFieldset(this.sizeTarget)
      this.disableFieldset(this.colorTarget)
      this.disableFieldset(this.designTarget)
      this.disableFieldset(this.typeTarget)
    } else if (costumeType === 'tuxedo' || costumeType === 'man') {
      this.disableFieldset(this.silhouetteTarget)
      this.disableFieldset(this.sizeTarget)
      this.enableFieldset(this.colorTarget)
      this.disableFieldset(this.designTarget)
      this.disableFieldset(this.typeTarget)
    } else if (categoryType == 'kimono') {
      this.disableFieldset(this.silhouetteTarget)
      this.disableFieldset(this.sizeTarget)
      this.enableFieldset(this.colorTarget)
      this.disableFieldset(this.designTarget)
      this.enableFieldset(this.typeTarget)
    } else {
      this.enableFieldset(this.silhouetteTarget)
      this.enableFieldset(this.sizeTarget)
      this.enableFieldset(this.colorTarget)
      this.enableFieldset(this.designTarget)
      this.enableFieldset(this.typeTarget)
    }
  }

  enableFieldset(fieldset) {
    fieldset.disabled = false;
    fieldset.classList.remove('is-hidden')
  }

  disableFieldset(fieldset) {
    fieldset.disabled = true;
    fieldset.classList.add('is-hidden')
  }
}
