import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // 選択したdatalistのアイテムを表示する要素
  static targets = [ 'selectedContainer' ]
  // model: モデル名
  // targetModel: datalistで選択して保存したいモデル
  // target: 選択したdatalistの値をタグとして表示させるノードのclass名
  // itemsValue: datalistのid
  static values = {
    model: String,
    targetModel: String,
    target: String,
    items: String
  }

  add(e) {
    const target = e.currentTarget
    if (!target) return
    const option = document.querySelector(`#${this.itemsValue} option[value="${target.value.replace(/["\\]/g, '\\$&')}"]`)
    if (!option) return
    const li = document.createElement('li')
    li.classList.add(this.targetValue)
    li.innerHTML = this.template(target.value, eval(`option.dataset.${this.targetModelValue}_id`))
    this.container.append(li)
    option.remove()
    target.value = ''
  }

  remove(e) {
    const item = e.currentTarget.closest(`.${this.targetValue}`)
    if (!item) return
    const selected = item.querySelector(`.selected_${this.targetModelValue}s`)
    if (!selected) return
    const option = document.createElement('option')
    option.setAttribute(`data-${this.targetModelValue}_id`, selected.value)
    option.setAttribute('value', selected.dataset.name)
    document.querySelector(`#${this.itemsValue}`).append(option)
    item.remove()
  }

  template(name, modelId) {
    return `<span class="set-${this.modelValue}-name">${this.escapeHtml(name)}</span>
<i class="fa fa-times" data-action="click->admin--multiple-datalist#remove"></i>
<input type="hidden" value="${modelId}" name="${this.modelValue}[${this.targetModelValue}_ids][]" data-name="${this.escapeHtml(name)}" class="selected_${this.targetModelValue}s" />`
  }

  escapeHtml(str) {
    return str
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  get container() {
    return this.selectedContainerTarget
  }
}
