import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  selectArea(event) {
    const sel = event.currentTarget
    const area = sel.options[sel.selectedIndex].text
    const prevArea = sel.dataset.trackEvent.split(' ')[2]
    sel.dataset.trackEvent = sel.dataset.trackEvent.replace(prevArea, area)

    location.href = sel.value
  }
}
