import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  static targets = [ "prevButton", "nextButton" ]

  connect() {
    new Swiper(this.element, {
      initialSlide: 1,
      centeredSlides: true,
      slidesPerView: 'auto',
      spaceBetween: 30,
      effect: 'coverflow',
      coverflowEffect: {
        slideShadows: false,
        rotate: 0,
        stretch: -70,
        depth: 500,
        modifier: 1,
      },
      navigation: {
        prevEl: this.prevButtonTarget,
        nextEl: this.nextButtonTarget,
      },
    })
  }
}
