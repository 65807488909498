import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  static targets = [ 'mainSlider', 'thumbnailSlider' ]

  connect() {
    const thumb = new Swiper(this.thumbnailSliderTarget, {
      slidesPerView: 3,
      width: 174,
      allowTouchMove: false,
    })
    const main = new Swiper(this.mainSliderTarget, {
      loop: true,
      slidesPerView: 'auto',
      thumbs: {
        swiper: thumb
      },
      pagination: {
        el: ".swiper-pagination",
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + "</span>";
        },
      },
    })
  }
}
