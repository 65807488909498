import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  add() {
    const lastInput = document.querySelector('.awards-input:last-of-type')
    if (lastInput.dataset.id === '1') {
      this.enableDeleteButton()
    }
    lastInput.insertAdjacentElement('afterend', this.newInput(lastInput))
  }

  delete() {
    const lastInput = document.querySelector('.awards-input:last-of-type')
    if (lastInput.dataset.id === '2') {
      this.disableDeleteButton()
    }
    lastInput.remove()
  }

  enableDeleteButton() {
    const deleteButton = document.querySelector('.staff-awards-delete-button')
    deleteButton.disabled = false
    deleteButton.classList.remove('hidden')
  }

  disableDeleteButton() {
    const deleteButton = document.querySelector('.staff-awards-delete-button')
    deleteButton.disabled = true
    deleteButton.classList.add('hidden')
  }

  newInput(orig) {
    const newInput = orig.cloneNode()
    newInput.value = ''
    newInput.dataset.id = parseInt(orig.dataset.id) + 1
    newInput.id = orig.id.replace(orig.dataset.id, newInput.dataset.id)
    return newInput
  }
}
