import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'toggle' ]

  toggle(event) {
    event.preventDefault();
    this.toggleTargets.forEach(target => {
      target.classList.toggle(target.dataset.classname || 'is-active')
    });
  }
}
