import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'player', 'thumb' ]

  connect() {
    if (!document.getElementById('youtube-iframe-api-loader')) {
      const tag = document.createElement('script')
      tag.id = 'youtube-iframe-api-loader'
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

      if (!window.onYouTubeIframeAPIReady) {
        window.onYouTubeIframeAPIReady = () => {
          window.isYouTubeIframeAPIReady = true;
        }
      }
    }
  }

  disconnect() {
    if (this.player) {
      this.player.destroy()
      this.player = undefined

      this.thumbTarget.classList.remove('is-hidden')
    }
  }

  click() {
    if (window.isYouTubeIframeAPIReady) {
      this.player = new YT.Player(this.playerTarget.id, {
        height: '360',
        width: '640',
        videoId: this.playerTarget.dataset.videoId,
        playerVars: {
          modestbranding: 1,
          rel: 0
        },
        events: {
          'onReady': (event) => {
            event.target.playVideo();
          }
        }
      })

      this.thumbTarget.classList.add('is-hidden')
    }
  }
}
