import DragSortController from './drag_sort_controller'
import Rails from '@rails/ujs'

export default class extends DragSortController {
  end(event) {
    const diff = event.newIndex - event.oldIndex
    if (diff == 0) {
      return
    }

    const id = event.item.dataset.id
    const dest_index = event.newIndex + (diff > 0 ? -1 : 1)
    const dest_id = event.item.parentElement.children[dest_index].dataset.id
    const data = new FormData()
    data.append('dest_id', dest_id)

    Rails.ajax({
      url: this.urlValue.replace(':id', id),
      type: 'PATCH',
      data: data,
    })
  }
}
