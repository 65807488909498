class BlockAccordion {
  constructor(targetDOM) {
    this.setParams(targetDOM)
    this.bindEvent()
  }
  setParams(targetDOM) {
    this.buttons = Array.prototype.slice.call(
      targetDOM.getElementsByClassName('js-block-accordion-trigger')
    )
    this.contents = Array.prototype.slice.call(
      targetDOM.getElementsByClassName('js-block-accordion-target')
    )
  }
  bindEvent() {
    this.buttons.forEach((element, index) => {
      element.addEventListener('click', e => {
        e.preventDefault()
        console.log('accordion')
        this.accordion(index)
      })
    })
  }
  accordion(index) {
    this.buttons[index].classList.toggle('is-active')
    this.contents[index].classList.toggle('is-active')
  }
}
export default BlockAccordion
